








import Vue from 'vue';
import axios from 'axios';
import { getLegacyApiUrl } from '@/env';

export default Vue.extend({
  name: 'Privacy',
  data() {
    return {
      privacy: '',
    };
  },
  async created() {
    const result = await axios({
      url: `${getLegacyApiUrl()}/StaticMessages/metadata?`,
      method: 'GET',
    });
    this.privacy = result?.data?.privacy;
  },
});
